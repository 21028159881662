.entry-content>* {
    margin-bottom: 0 !important;
}

.ct-header-cta {
    a {
        padding: 2px 30px;
        font-size: 13px;
        line-height: 22.75px;
    }
    .b-header {
        padding: 0 30px !important;
    }
}

nav[class*=menu] li[class*=current-menu-]>a {
    font-family: Kanit, Sans-Serif;;
    font-size: 20px;
    background: linear-gradient(102.6deg, #A7D48C 0.35%, #7DDFD2 100.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

p.gb-headline {
    mark {
        font-weight: 500 !important;
    }
}

ul.home-usp {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 !important;
    margin: 0 !important;
    li {
        padding-left: 40px;
        margin: 0;
        &:first-child {
            list-style: none;
            padding-left: 0;
        }
    }
}

.style-headline {
    margin-bottom: 7px !important;
    .gb-icon {
        display: flex !important;
        justify-content: center;
        align-self: center;
        align-items: center;
        padding-bottom: 0 !important;
        margin-bottom: 28px;
        width: 120px;
        height: 120px;
        background-color: var(--paletteColor8);
        border-radius: 100%;
    }
}

.style-headline2 {
    margin-bottom: 17px !important;
    .gb-icon {
        display: flex !important;
        justify-content: center;
        align-self: center;
        align-items: center;
        padding-bottom: 0 !important;
        margin-bottom: 15px;
        width: 80px;
        height: 80px;
        background-color: var(--paletteColor8);
        border-radius: 100%;
    }
}

.c-shadow {
    box-shadow: 0px 4px 8px 0px #00000040;
}

.g-h-style {
    justify-content: space-between;
    flex-direction: row-reverse;
}

.back-element__left-light {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 46vw;
        height: 100%;
        background-color: var(--paletteColor8);
        z-index: -1;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.back-element__left {
    .wp-block-image {
        figure {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 25px;
                right: 22px;
                width: 100%;
                height: 100%;
                background-color: var(--paletteColor8);
                z-index: -1;
                border-radius: 20px;
            }
        }
    }
}
.back-element__right {
    .wp-block-image {
        figure {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                bottom: -25px;
                left: -22px;
                width: 100%;
                height: 100%;
                background-color: var(--paletteColor8);
                z-index: -1;
                border-radius: 20px;
            }
        }
    }
}
.back-element__right2 {
    .wp-block-image {
        figure {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                bottom: -23px;
                right: 0;
                width: 502px;
                height: 361px;
                background-color: var(--paletteColor8);
                z-index: -1;
                border-radius: 20px;
            }
        }
    }
}

.back-element__left-light2 {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 51vw;
        height: 100%;
        background-color: var(--paletteColor8);
        z-index: -1;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.style-buttons {
    gap: 25px;
}

.img-rounded {
    text-align: center;
    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
    }
}

.back-element__right-light {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 46vw;
        height: 100%;
        background-color: var(--paletteColor8);
        z-index: -1;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}

.hero-container {
    margin-bottom: 41px !important;
    margin-left: 75px !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    border-radius: 0px 10px 10px 0px;
    &:before {
        content: '';
        position: absolute;
        margin-left: -21px;
        width: 43px;
        height: 212px;
        background: linear-gradient(180deg, #A7D48C 0%, #04BFAD 100%);
        border-radius: 10px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}

.style-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        position: relative;
        padding-left: 43px;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
        display: flex;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 33px;
            height: 25px;
            background: url(/wp-content/uploads/2023/04/Vector.png);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-self: center;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.style-list2 {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 34px;
    li {
        position: relative;
        padding-left: 35px;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 5px;
        display: flex;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 25px;
            height: 18px;
            background: url(/wp-content/uploads/2023/04/Vector.png);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-self: center;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

figure.alignright,
figure.alignleft {
    margin: 0 !important;
    img {
        border-radius: 20px;
    }
}
figure.size-full {
    img {
        width: 100%;
    }
}
figure.is-style-rounded {
    img {
        width: auto;
    }
}
#block-5,
#block-6 {
    figure.size-full {
        img {
            width: auto;
        }
    }
}

.spacer {
    display: none;
}

#gform_wrapper_3 {
    .gform_body {
        .gform_fields {
            grid-column-gap: 4%;
            grid-row-gap: 12px;
        }
        .gfield {
            input,
            select,
            textarea {
                padding: 8px 13px;
            }
            select {
                background-image: url(/wp-content/uploads/2023/04/Group72.png);
                background-size: 30px auto,100%;
                background-position: right 23px top 50%,0 0;
            }
            label.gfield_label {
                margin-bottom: 0;
                font-weight: 500 !important;
            }
        }
        #field_3_19 {
            textarea {
                height: 118px;
            }
        }
        #field_3_18 {
            grid-column: span 6;
        }
        #field_submit {
            height: 100%;
            margin-top: -31px;
            grid-column: 7/-1 !important;
            input {
                padding: 8px 50px;
            }
        }
    }
}

.grid-4 {
    .gb-grid-column {
        .gb-container {
            .gb-inside-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                .wp-block-buttons {
                    margin-top: auto;
                }
            }
        }
    }
    .gb-grid-column-6f71f2b4,
    .gb-grid-column-c545bd5d {
        padding-bottom: 0 !important;
    }
    .wp-block-button {
        a {
            font-size: 16px;
            min-height: 45px;
        }
    }
}

.ct-footer {
    [data-row="middle"] {
        .ct-container {
            .ct-widget {
                .widget-title {
                    position: relative;
                    display: inline-block;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 9px;
                        right: -20px;
                        width: 10px;
                        height: 10px;
                        background-color: var(--paletteColor1);
                        border-radius: 100%;
                    }
                }
            }
            #blocksy_ct_socials-3 {
                margin-top: 20px;
                .widget-title {
                    display: none;
                }
                .ct-social-box {
                    a {
                        .ct-icon-container {
                            svg {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }
            #block-5 {
                margin-top: 20px;
            }
            #block-6 {
                margin-top: 0;
            }
        }
    }
    [data-row="bottom"] {
        background: linear-gradient(90.07deg, #A7D48C 0%, #7CDFD3 100%);
    }
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--block-width, var(--container-width));
        max-width: var(--block-max-width, var(--normal-container-max-width));
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
    }
}

@include break-below(lgt) {
    .hero-container {
        &:before {
            position: initial;
        }
    }
}

@include break-below(mdl) {
    .back-element__right-light {
        &:before {
            display: none;
        }
    }
    .back-element__left-light {
        &:before {
            display: none;
        }
    }
    .back-element__left-light2 {
        &:before {
            display: none;
        }
    }
    figure.alignright,
    figure.alignleft {
        float: none !important;
        margin: 0 auto !important;
        img {
            
        }
    }
}

@include break-below(smm) {
    
}

@include break-below(sm) {
    .h-hero-title {
        font-size: 36px !important;
        line-height: 45px !important;
    }
    .hero-container {
        margin-left: 30px !important;
        h1 {
            font-size: 36px !important;
            line-height: 45px !important;
        }
    }
    ul.home-usp {
        li {
            padding: 0;
            list-style: none;
        }
    }
    #gform_wrapper_3 {
        .gform_body {
            #field_submit {
                margin-top: 10px;
                grid-column: 1/-1 !important;
            }
        }
    }
}